var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition-group",
    { staticClass: "notifications", attrs: { tag: "div", name: "slide-fade" } },
    _vm._l(_vm.notifications, function (notification) {
      return _c("SfNotification", {
        key: notification.id,
        attrs: {
          message: notification.message,
          action: notification.action && notification.action.text,
          type: notification.type,
          visible: "",
        },
        on: {
          "click:close": notification.dismiss,
          "click:action": function ($event) {
            notification.action && notification.action.onClick()
          },
        },
        scopedSlots: _vm._u(
          [
            notification.icon
              ? {
                  key: "icon",
                  fn: function () {
                    return [
                      _c("SvgImage", {
                        attrs: {
                          icon: notification.icon,
                          width: "25",
                          height: "25",
                        },
                      }),
                    ]
                  },
                  proxy: true,
                }
              : null,
          ],
          null,
          true
        ),
      })
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }